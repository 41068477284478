import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Cards from '@molecules/cards'
import Text from '@particles/text'
import SEO from '@atoms/seo'

const ServicesPage = ({ data, location }) => {
  const services = data.services.edges

  const sorted = services.sort(({node: a}, {node: b}) => {
    return b.instantMetricsCount - a.instantMetricsCount
  })
  return (
    <Layout location={location}>
    <SEO title={`All Services`} location={location} />
      <Heading center h1 margin="T4 B1">
        All services
      </Heading>
      <Text center size={1.25} margin="B4" style={{ maxWidth: '550px', margin: '0 auto 4rem' }}>
        Choose your service below. Instantly learn and track the metrics that
        matter the most to your business success.
      </Text>
      <Layout.Grid fixed={294} gap={1.25}>
        {sorted.map(({ node }, i) => {
          if(node.instantMetricsCount === 0) return null
          return (
            <Cards.Service
              auto="true"
              src={
                node.logo.cdn
              }
              name={node.name}
              subtitle={`${node.instantMetricsCount} metric${
                node.instantMetricsCount === 1 ? '' : 's'
              }`}
              link={node.slug}
              key={`${node.name}-${i}`}
            />
          )
        })}
      </Layout.Grid>
    </Layout>
  )
}
ServicesPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}
export default ServicesPage

export const pageQuery = graphql`
  query AllServicesQuery {
    services: allServices(filter: {status: {eq: "published"}}) {
      edges {
        node {
          logo {
            cdn
          }
          slug
          name
          instantMetricsCount
        }
      }
    }
  }
`
